var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
    _c("path", {
      staticClass: "fill-current",
      attrs: {
        d: "M6.876 15.124A6.002 6.002 0 0 0 17.658 14h2.09a8.003 8.003 \n    0 0 1-14.316 2.568L3 19v-6h6l-2.124 2.124zm10.249-6.249A6.004 \n    6.004 0 0 0 6.34 10H4.25a8.005 8.005 0 0 1 14.32-2.57L21 5v6h-6l2.125-2.125z",
        "fill-rule": "evenodd",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }